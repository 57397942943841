import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HeaderStepsComponent } from '../header-steps/header-steps.component';
@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['../steps.component.scss']
})

@NgModule({
  imports: [ BrowserModule ],
  declarations: [ HeaderStepsComponent ]
})
export class StartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
