import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { StepsComponent } from './steps/steps.component';

import { MaterialModule } from './shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndustryComponent } from './steps/industry/industry.component';
import { BusinessGoalComponent } from './steps/business-goal/business-goal.component';

import { AppRoutingModule } from './app-routing.modules';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResultComponent } from './result/result.component';
import { HttpClientModule } from '@angular/common/http';
import { StartComponent } from './steps/start/star.component';
import { BusinessNameComponent } from './steps/business-name/business-name.component';
import { HeaderStepsComponent } from './steps/header-steps/header-steps.component';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { ImpactsCovidComponent } from './steps/impacts-covid/impacts-covid.component';
import { FormForResult } from './formForResults/form-for-results';



@NgModule({
  declarations: [
    AppComponent,
    StepsComponent,
    IndustryComponent,
    BusinessGoalComponent,
    ResultComponent,
    StartComponent,
    BusinessNameComponent,
    HeaderStepsComponent,
    ImpactsCovidComponent,
    FormForResult
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularFullpageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
