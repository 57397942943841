import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import * as data from '../../assets/data.json';
import * as $ from 'jquery';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})

export class ResultComponent implements OnInit {

    config: any;
    fullpage_api: any;
    resultData: any = (data as any).default; 
    info: string;
    textGoal: string;
    industry: string;
    businessName: string;
    impacts: string;
    businessGoals: string;
    condition: boolean = false;
    formResult: FormGroup;
    formResultPopup: FormGroup;
    formPopupWebsite: FormGroup;

    conditionFood: boolean = true;
    conditionContraction: boolean = true;

    textIndex:number;
    href: string;
    constructor( 
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
    ) {

        this.config = {
            menu: '#myMenu',
            scrollOverflow: true,
            scrollingSpeed: 1000,
            scrollOverflowOptions: {
                preventDefault: false
            },
            onLeave : (origin, destination, direction , index) =>{

                if(destination.index == 2 || destination.index == 3 ){
                    $('.fixed_phone.left').addClass("show");
                }else{
                    $(".fixed_phone.left").removeClass("show");
                }

                var rightBlock = $(".fullpage .section").eq(4);
                var rightBlockHelp = $(".fullpage .section").eq(5);

                if(destination.index == 4 || destination.index == 5){
                    $('.fixed_phone.right').addClass("show");
                    rightBlock.addClass("right_block");
                    rightBlockHelp.addClass("right_block");
                }else{
                    $(".fixed_phone.right").removeClass("show");
                }

                if(destination.index <= 5){
                    $('.fixed_phone.common').removeClass("show");
                }else{
                    $('.fixed_phone.common').addClass("show");
                }

                let info: any = this.info;

                let showImg1 = $('.fixed_phone.show').find('img.normal');
                let showImg2 = $('.fixed_phone.show').find('img.help_section');

                if(destination.anchor == "domain"){
                    let imgSrc = info[0].domain.imgForMobie;
                    console.log(imgSrc)
                    showImg1.attr("src" , imgSrc).fadeIn(1500);
                    showImg2.fadeOut(400);
                }

                if(destination.anchor == "domainHelp"){
                    let imgSrc = info[0].domain.imgForMobie2;
                    showImg2.attr("src" , imgSrc).fadeIn(1500);
                    showImg1.fadeOut(400);
                    $("#myMenu").find(".domain_li").addClass("active_link");
                }else{
                    $("#myMenu").find(".domain_li").removeClass("active_link");
                }

                if(destination.anchor == "website"){
                    let imgSrc = info[0].website.imgForMobie;
                    showImg1.attr("src" , imgSrc).fadeIn(1500);
                    showImg2.fadeOut(400);
                }

                if(destination.anchor == "websiteHelp"){
                    let imgSrc = info[0].website.imgForMobie2;
                    showImg2.attr("src" , imgSrc).fadeIn(1500);
                    showImg1.fadeOut(400);
                    $("#myMenu").find(".website_li").addClass("active_link");
                }else{
                    $("#myMenu").find(".website_li").removeClass("active_link");
                }

                if(destination.anchor == "onlineStore"){
                    let imgSrc = info[0].onlineStore.imgForMobie;
                    showImg1.attr("src" , imgSrc).fadeIn(1500);
                    showImg2.fadeOut(400);
                }

                if(destination.anchor == "onlineStoreHelp"){
                    let imgSrc = info[0].onlineStore.imgForMobie2;
                    showImg2.attr("src" , imgSrc).fadeIn(1500);
                    showImg1.fadeOut(400);
                    $("#myMenu").find(".onineStore_li").addClass("active_link");
                }else{
                    $("#myMenu").find(".onineStore_li").removeClass("active_link");
                }

                if(destination.anchor == "onlineDirectories"){
                    let imgSrc = info[0].online_directories.imgForMobie;
                    showImg1.attr("src" , imgSrc).fadeIn(1500);
                    showImg2.fadeOut(400);
                }

                if(destination.anchor == "onlineDirectoriesHelp"){
                    let imgSrc = info[0].online_directories.imgForMobie2;
                    showImg2.attr("src" , imgSrc).fadeIn(1500);
                    showImg1.fadeOut(400);
                    $("#myMenu").find(".onlineDirectories_li").addClass("active_link");
                }else{
                    $("#myMenu").find(".onlineDirectories_li").removeClass("active_link");
                }

                if(destination.anchor == "onlineReviews"){
                    let imgSrc = info[0].onlineReviews.imgForMobie;
                    showImg1.attr("src" , imgSrc).fadeIn(1500);
                    showImg2.fadeOut(400);
                }
                if(destination.anchor == "onlineReviewsHelp"){
                    let imgSrc = info[0].onlineReviews.imgForMobie2;
                    showImg2.attr("src" , imgSrc).fadeIn(1500);;
                    showImg1.fadeOut(400);
                    $("#myMenu").find(".onlineReviews_li").addClass("active_link");
                }else{
                    $("#myMenu").find(".onlineReviews_li").removeClass("active_link");
                }
               
                
                if(destination.anchor == "form"){
                    $("#myMenu").fadeOut();
                    $('.fixed_phone.common').removeClass("show");
                    $(".popup_form.form_section").fadeOut();
                }else{
                    $(".popup_form.form_section").fadeIn();
                    $("#myMenu").fadeIn();
                }

            },
           
        };
    }

    getRef(fullPageRef) {
        this.fullpage_api = fullPageRef;
        if($(window).width() < 900){
            this.fullpage_api.destroy('all');
        }
    }
    
    ngOnInit() {
        function heightWindow(){
            if($(window).width() < 1120 && $(window).height() > 600){
                $(".fixed_phone.common .social_icon").addClass("more_6");
            }else{
                $(".fixed_phone.common .social_icon").removeClass("more_6");
            }
        }
    
        $(window).resize(function(){
            heightWindow();
        })
    
        $(document).ready(function(){

            heightWindow();
            
            $(".fullpage_menu li").each(function(){
                $(".header_li a").html($(".header_li").index());
                $(".logo_li a").html($(".logo_li").index());
                $(".incorporation_li a").html($(".incorporation_li").index());
                $(".domain_li a").html($(".domain_li").index());
                $(".website_li a").html($(".website_li").index());
                $(".onineStore_li a").html($(".onineStore_li").index());
                $(".appointment_li a").html($(".appointment_li").index());
                $(".offers_li a").html($(".offers_li").index());
                $(".onlineDirectories_li a").html($(".onlineDirectories_li").index());
                $(".emailMarketing_li a").html($(".emailMarketing_li").index());
                $(".social_li a").html($(".social_li").index());
                $(".socialStore_li a").html($(".socialStore_li").index());
                $(".seo_li a").html($(".seo_li").index());
                $(".onlineReviews_li a").html($(".onlineReviews_li").index());
            })
            $(".covid_block").each(function(){

                var click = $(".title", $(this));
                var hidden = $(".hidden_block" , $(this));

                click.on("click", function(){
                    $(this).parent().toggleClass("active");
                    hidden.slideToggle();
                })
            })

            $(".js_scroll").click(function() {
                $('html, body').animate({
                    scrollTop: $("#form_section").offset().top
                }, 2000);
            });

            $(".mobile_block_button").each(function(){
                let container = $(this);
                let btn = $(".js_help", $(this));
                let block = $(this).next(".help_block");
                btn.on("click", function(e){
                    e.preventDefault();
                    block.slideToggle();
                    if(btn.hasClass("active")){
                        btn.removeClass("active")
                    }else{
                        btn.addClass('active');
                    }
                });
            });
        });
            

        this.router.navigate(['result'])

        this.businessGoals = "starting_new_business";
        this.industry = "other";
        
        if(this.industry) {
            this.info = this.resultData.filter(function(i){
                
                return (i.id === 'other');
            })
        }
        //Industry-step
        
        // Goal-step
        
        if(this.businessGoals){
            this.textGoal = this.resultData.filter(function(i){
                return (i.id === "starting_new_business")
            })
           
        }
        //Goal-step
    }
}
