import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators , FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/';

@Component({
  selector: 'app-business-goal',
  templateUrl: './business-goal.component.html',
  styleUrls: ['../steps.component.scss']
})

export class BusinessGoalComponent implements OnInit {
  formStepGoals: FormGroup;
  sub: any;
  page : any;

  //pagitation settings
  color: ThemePalette = 'primary';
  mode: MatProgressBarModule = 'determinate';
  value = 75;
  bufferValue = 100;
  //pagitation settings

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }
    
  ngOnInit() {
    let valueItem = localStorage.getItem('business_goals');
    this.formStepGoals = this.formBuilder.group({
      business_goals: [valueItem, [Validators.required]]
    })
  }
  get form() { return this.formStepGoals.controls;}
  
  getFormValue(){
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.page = this.formStepGoals.value.business_goals
    });
    this.router.navigate(['/result'], { queryParams: { "business_goals": this.page}, queryParamsHandling: "merge" });
    localStorage.setItem('business_goals', this.formStepGoals.value.business_goals);
  }

}
