import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material';

@Component({
  selector: 'app-impacts-covid',
  templateUrl: './impacts-covid.component.html',
  styleUrls: ['../steps.component.scss']
})
export class ImpactsCovidComponent implements OnInit {

  formImpacts: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router
    ) {
  }
  
  sub: any;
  page : any;
  businessName: string;

  //pagitation settings
  color: ThemePalette = 'primary';
  mode: MatProgressBarModule = 'determinate';
  value = 50;
  bufferValue = 100;
  //pagitation settings
  
  ngOnInit(){

    let valueItem = localStorage.getItem('impacts_covid');

    this.formImpacts =  this.formBuilder.group({
      impacts_covid: [valueItem, [Validators.required]],
    })
  }
  get form() { return this.formImpacts.controls;}
  
  getFormValue(){
    
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      this.page = this.formImpacts.value.impacts_covid;
    });
    this.router.navigate(['/business-goal'], { queryParams: { "impacts_covid": this.page}, queryParamsHandling: "merge"});
    localStorage.setItem('impacts_covid', this.formImpacts.value.impacts_covid);
      
  }

}
