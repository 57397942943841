import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { StartComponent } from './steps/start/star.component';
import { BusinessNameComponent } from './steps/business-name/business-name.component';
import { IndustryComponent } from "./steps/industry/industry.component";
import { BusinessGoalComponent } from "./steps/business-goal/business-goal.component";
import { ImpactsCovidComponent } from './steps/impacts-covid/impacts-covid.component';
import { ResultComponent } from './result/result.component';

const appRoutes : Routes =[
    {
        path: "impacts-covid",
        component: ImpactsCovidComponent
    },
    {
        path: "business-name",
        component: BusinessNameComponent
    },
    {
        path: "get-start",
        component: StartComponent
    },
    {
        path: "industry",
        component: IndustryComponent
    },
    {
        path: "business-goal",
        component: BusinessGoalComponent
    },
    {
        path: "result",
        component: ResultComponent
    },
    {
        path: '',
        redirectTo: '/result',
        pathMatch: 'full'
    },
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}