import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import * as $ from 'jquery';

@Component({
    selector: 'app-form-for-results',
    templateUrl: './form-for-results.html',
    styleUrls: ['./form-for-results.scss']
  })

export class FormForResult implements OnInit {
    industry: string;
    businessName: string;
    impacts: string;
    businessGoals: string;
    formResult: FormGroup;
    formSucsess:boolean = false;
    formSucsessPopup:boolean = false;
    //formSucsessPopup:boolean = false;
    requestCall:boolean = false;
    popupOrder: string;
    constructor( 
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private http: HttpClient,
    ){
    }
    ngOnInit(){
        this.formResult = this.formBuilder.group({
            last_name : ['', Validators.required],
            email : ['', [Validators.required , Validators.email]],
            phone : ['', [Validators.required,  Validators.minLength(5)]],
        });

        this.route.queryParams.subscribe(params => {
            this.businessName = params['business_name'] || localStorage.getItem('business_name');
            this.industry = params['industry'] || localStorage.getItem('industry');
            this.impacts = params['impacts_covid'] || localStorage.getItem('impacts_covid');
            this.businessGoals = params['business_goals'] || localStorage.getItem('business_goals');
            
        });
        if(this.industry === "food"){
            $(".popup_successful .orange_btn").addClass("section_food");
            $("form .orange_btn").addClass("section_food");
        }
        if(this.industry === "building_contractor"){
            $(".popup_successful .orange_btn").addClass("section_contractor");
            $("form .orange_btn").addClass("section_contractor");
        }
        if(this.industry === "personal_services"){
            $(".popup_successful .orange_btn").addClass("section_services");
            $("form .orange_btn").addClass("section_services");
        }
        
        this.popupOrder = localStorage.getItem('opepPopup');

        if(this.popupOrder === "true"){
            $(".form_for_user").addClass("openPopupOrder")
        }else{
            $(".form_for_user").removeClass("openPopupOrder")
        }
    }
    onSubmit(e) {
        let dataJson = {
            last_name : this.formResult.value.last_name || this.formResult.value.last_name1,
            email : this.formResult.value.email || this.formResult.value.email1,
            phone : this.formResult.value.phone || this.formResult.value.phone1,
            business_name: this.businessName,
            industry : this.industry,
            impacts : this.impacts,
            businessGoals: this.businessGoals
        }
        
        
        this.http.post<any>('https://hooks.zapier.com/hooks/catch/6677519/os62lal/', JSON.stringify(dataJson)).subscribe(data => {
            //this.formSucsessPopup.emit(true)
            // this.requestCall = true;
            this.formResult.reset();
            this.formResult.controls.last_name.setErrors(null);
            this.formResult.controls.email.setErrors(null);
            this.formResult.controls.phone.setErrors(null);
            
            if(this.popupOrder === "true"){
                console.log(this.popupOrder)
                this.formSucsessPopup = true;
            }

            
        })
        // https://hooks.zapier.com/hooks/catch/4559531/oiopkyj/
    }
    get form() { return this.formResult.controls;}
    closePopup(){
        // this.requestCall = false;
        // this.isScrollPopup = true;
        this.formSucsess = false;
    }
}